import * as React from 'react'

import './RoleIcon.css'

export interface IRoleIconProps extends React.PropsWithChildren<any> {
    title?: string
    isActive?: boolean
    onClick?: (e: React.MouseEvent) => void
}

function RoleIcon(props: IRoleIconProps) {

    return (
        <div className="role" onClick={props.onClick} style={props.isActive ? { fill: "#c5b9d0" } : { fill: "#604973" }}>
            <svg viewBox="0 0 210 210" width="35" height="35">
                <title>{props.title}</title>
                {props.children}
            </svg>
        </div>
    )
}

export default RoleIcon