import * as React from 'react'

import RoleIcon, { IRoleIconProps } from './RoleIcon'

function MeleeAssassinIcon(props: IRoleIconProps) {
    return (
        <RoleIcon title="Melee Assassin" onClick={props.onClick} isActive={props.isActive}>
            <path d="M111,202.5c15-10,29.22-31.43,32-41-9,0-14-13-14-22v-47l-18,16Z"></path><path d="M105,202.5c-15-10-29.22-31.43-32-41,9,0,14-13,14-22v-47l18,16Z"></path><path d="M150.28,37.44C150.28,55.28,135,67.5,126.5,73c-4.82,3.09-11.23,5.45-11.23-1.82V40.5c0-9.74,7.26-9,7.26-9,0-9.74-14.53-18-14.53-18s-14.53,8.26-14.53,18c0,0,7.26-.74,7.26,9V71.13c0,7.27-6.41,4.91-11.23,1.82C81,67.5,65.72,55.28,65.72,37.44,53.83,59.24,82.4,81.7,108,103.5,133.6,81.7,162.17,59.24,150.28,37.44Z"></path>
        </RoleIcon>
    )
}

export default MeleeAssassinIcon