import { IHero } from "../../tools/interfaces";
import Hexagon from "./Hexagon";
import { useMedia } from "@gamate/common";
import styled from "styled-components";
import { useCallback } from "react";

interface IProps {
  heroes: IHero[];
  onClick: (heroName: string) => void;
  isMirrored?: boolean;
  teamColor: string;
  highlightedIndexes: number[];
  isPickOrderDisplayed?: boolean;
}

const MobileQuestionMarkContainer = styled.span<any>`
  font-size: 30px;
  text-shadow: 2px 2px 2px white;
  left: -2px;
  position: relative;
  top: -2px;
  color: ${(props) => props.color};
`;

function Team(props: IProps) {
  const { isDesktopBrowsing } = useMedia();

  const hexagonContent = useCallback(
    (hero: IHero) => {
      if (hero.name) return null;
      if (isDesktopBrowsing) {
        return (
          <span className="no-pick" style={{ color: props.teamColor }}>
            ?
          </span>
        );
      }
      return <MobileQuestionMarkContainer color={props.teamColor}>?</MobileQuestionMarkContainer>;
    },
    [isDesktopBrowsing, props.teamColor]
  );

  let team;
  if (isDesktopBrowsing) {
    team = props.heroes.map((hero: IHero, index: number) => {
      const isShiftCondition = props.isMirrored ? index % 2 === 0 : index % 2 !== 0;
      const defaultStyle = hero.name ? { cursor: "pointer" } : {};
      const style = isShiftCondition
        ? { ...defaultStyle, marginTop: "-30px", marginLeft: "65px", marginBottom: "-30px" }
        : defaultStyle;
      return (
        <div key={`${index}${hero.name}`} onClick={() => props.onClick(hero.name)} style={style}>
          <Hexagon
            imgUrl={hero.circleImgUrl}
            isHighlighted={props.highlightedIndexes.includes(index)}
            borderColor={props.teamColor}
            size="large"
          >
            {hexagonContent(hero)}
          </Hexagon>
          {props.isPickOrderDisplayed && (
            <div
              style={{
                height: 0,
                position: "relative",
                top: "-20px",
                left: isShiftCondition ? "110px" : "10px",
              }}
            >
              {hero.pickOrder}
            </div>
          )}
        </div>
      );
    });
  } else {
    team = props.heroes.map((hero: IHero, index: number) => {
      return (
        <div
          key={`${index}${hero.name}`}
          onClick={() => props.onClick(hero.name)}
        >
          <Hexagon
            imgUrl={hero.circleImgUrl}
            isHighlighted={props.highlightedIndexes.includes(index)}
            borderColor={props.teamColor}
            size="small"
          >
            {hexagonContent(hero)}
          </Hexagon>
        </div>
      );
    });
  }
  return <>{team}</>;
}

Team.defaultProps = {
  heroes: [],
  onClick: () => {},
  highlightedIndexes: [],
};

export default Team;
