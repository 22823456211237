import { useEffect, useState } from "react";
import { useParams } from "react-router";
import styled, { css } from "styled-components";

import { useMedia } from "@gamate/common";

import HeroPool from "./HeroPool";
import Team from "./Team";
import { IHero, IResponsiveContainer } from "../../tools/interfaces";
import { heroService } from "../../tools/services";
import { useSuggestions } from "./customHooks";
import Link from "../../components/Link";
import MatchupHeader from "./MatchupHeader";
import MatchupActions from "./MatchupActions";

import "./Matchup.css";

// Map : Braxis holdout
// Stitches hook cd vs Sgt Hammer E (good on this map because can siege in middle of zerg wave)

// Anduin Ult can be interrupted by Tass black hole ult

// Bad synergy Ana with Tracer/Qhira because of their erratic movements

// good synergy zeratul vp + rehgar bloodlust

// towers of doom : abathur avec un build crottes c'est insupportable

// murky - infernal shrine? ragna - alterac?

// sonya counter xul : les skelettes donnent plus de cibles au tourbilol, un talent permet d'esquiver la cage avec le tourbilol

// https://www.tentonhammer.com/guides/heroes-of-the-storm-hero-counterpicks-guide

// ana weak vs diving heroes

const firstTeamIndexes = [4, 7, 8, 13, 14];
const firstTeamBanIndexes = [0, 2, 10];
const secondTeamIndexes = [5, 6, 11, 12, 15];
const secondTeamBanIndexes = [1, 3, 9];

const choGallAutoPickIndexes = [5, 7, 11, 13];
const choGallAutoUnpickPositions = [7, 9, 13, 15];

const Container = styled.div<IResponsiveContainer>`
  width: inherit;
`;

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  margin-bottom: 10px;
  word-break: break-all;
`;

const MatchupContentContainer = styled.div<IResponsiveContainer>``;

const BlueTeamContainer = styled.div<IResponsiveContainer>`
  ${(props) =>
    !props.isDesktop &&
    css`
      margin-top: 50px;
    `}
`;

const RedTeamContainer = styled.div<IResponsiveContainer>`
  margin-top: ${(props) => (props.isDesktop ? "30px" : "50px")};
`;

const HeroPoolContainer = styled.div<IResponsiveContainer>`
  ${(props) =>
    !props.isDesktop &&
    css`
      width: 225px;
    `}
`;

export default function Matchup() {
  const { version, code } = useParams<{ version: string; code: string }>();

  const [heroesPool, setHeroesPool] = useState([] as IHero[]);
  const [suggestions, setSuggestions] = useSuggestions();

  const [isMyTeamFirstPick, setIsMyTeamFirstPick] = useState(true);
  const [selection, setSelection] = useState([] as IHero[]);
  const [currentAction, setCurrentAction] = useState("");

  const [highlightedIndexes, setHighlightedIndexes] = useState([] as number[]);
  const [highlightedIndexes2, setHighlightedIndexes2] = useState([] as number[]);

  const [banIndex, setBanIndex] = useState(-1);
  const [banIndex2, setBanIndex2] = useState(-1);

  const [matchupUrl, setMatchupUrl] = useState("");

  const [lastPickMessage, setLastPickMessage] = useState("");
  const [isPickOrderDisplayed, setIsPickOrderDisplayed] = useState(false);

  const { isDesktopBrowsing } = useMedia();

  useEffect(() => {
    if (!isDesktopBrowsing) setIsPickOrderDisplayed(false);
  }, [isDesktopBrowsing]);

  useEffect(() => {
    (async () => {
      const heroesJson = await heroService.getAll();
      setHeroesPool(heroesJson);

      if (!code || version !== "0") return;
      setIsMyTeamFirstPick(parseInt(code[0]) === 1 ? true : false);
      const arr = [];
      for (let i = 1; i < code.length; i += 3) {
        const foundIndex = parseInt(code.slice(i, i + 3));
        if (!isNaN(foundIndex)) arr.push(foundIndex);
      }
      const initialSelection: IHero[] = [];
      arr.forEach((index) => {
        const hero = heroesJson[index];
        hero.pickOrder = index + 1;
        initialSelection.push(hero);
      });
      setSelection(initialSelection);
    })();
  }, [version, code]);

  useEffect(() => {
    const getCurrentAction = () => {
      const myTeamBanning = "blue team banning";
      const opponentBanning = "red team banning";
      const myTeamPicking = "blue team picking";
      const opponentPicking = "red team picking";

      switch (true) {
        case firstTeamBanIndexes.includes(selection.length):
          return isMyTeamFirstPick ? myTeamBanning : opponentBanning;
        case secondTeamBanIndexes.includes(selection.length):
          return isMyTeamFirstPick ? opponentBanning : myTeamBanning;
        case firstTeamIndexes.includes(selection.length):
          return isMyTeamFirstPick ? myTeamPicking : opponentPicking;
        case secondTeamIndexes.includes(selection.length):
          return isMyTeamFirstPick ? opponentPicking : myTeamPicking;
        default:
          return "";
      }
    };

    setCurrentAction(getCurrentAction());
  }, [isMyTeamFirstPick, selection]);

  useEffect(() => {
    if (selection.length > 0) {
      let code = isMyTeamFirstPick ? "1" : "0";
      selection.forEach((sh) => {
        const index = heroesPool.findIndex((h: IHero) => h.name === sh.name);
        code += index.toString().padStart(3, "0");
      });
      setMatchupUrl(`${document.location.origin}/matchup/0/${code}`);
    } else {
      setMatchupUrl("");
    }
  }, [isMyTeamFirstPick, selection, heroesPool]);

  useEffect(() => {
    switch (selection.length) {
      case 0: // ban
        setBanIndex(0);
        setBanIndex2(-1);
        setLastPickMessage("");
        break;
      case 1: // ban
        setBanIndex(-1);
        setBanIndex2(0);
        setLastPickMessage(`Banned ${selection[selection.length - 1].name}`);
        break;
      case 2: // ban
        setBanIndex(1);
        setBanIndex2(-1);
        setLastPickMessage(`Banned ${selection[selection.length - 1].name}`);
        break;
      case 3: // ban
        setBanIndex(-1);
        setBanIndex2(1);
        setHighlightedIndexes([]);
        setHighlightedIndexes2([]);
        setLastPickMessage(`Banned ${selection[selection.length - 1].name}`);
        break;
      case 4:
        setBanIndex(-1);
        setBanIndex2(-1);

        setHighlightedIndexes([0]);
        setHighlightedIndexes2([]);
        setLastPickMessage(`Banned ${selection[selection.length - 1].name}`);
        break;
      case 5:
      case 6:
        setHighlightedIndexes([]);
        setHighlightedIndexes2([0, 1]);
        setLastPickMessage(`Picked ${selection[selection.length - 1].name}`);
        break;
      case 7:
      case 8:
        setBanIndex(-1);
        setBanIndex2(-1);
        setHighlightedIndexes([1, 2]);
        setHighlightedIndexes2([]);
        setLastPickMessage(`Picked ${selection[selection.length - 1].name}`);
        break;
      case 9: // ban
        setBanIndex(-1);
        setBanIndex2(2);
        setHighlightedIndexes([]);
        setHighlightedIndexes2([]);
        setLastPickMessage(`Picked ${selection[selection.length - 1].name}`);
        break;
      case 10: // ban
        setBanIndex(2);
        setBanIndex2(-1);
        setHighlightedIndexes([]);
        setHighlightedIndexes2([]);
        setLastPickMessage(`Banned ${selection[selection.length - 1].name}`);
        break;
      case 11:
        setLastPickMessage(`Banned ${selection[selection.length - 1].name}`);
        setBanIndex(-1);
        setBanIndex2(-1);
        setHighlightedIndexes([]);
        setHighlightedIndexes2([2, 3]);
        break;
      case 12:
        setBanIndex(-1);
        setBanIndex2(-1);
        setHighlightedIndexes([]);
        setHighlightedIndexes2([2, 3]);
        setLastPickMessage(`Picked ${selection[selection.length - 1].name}`);
        break;
      case 13:
      case 14:
        setHighlightedIndexes([3, 4]);
        setHighlightedIndexes2([]);
        setLastPickMessage(`Picked ${selection[selection.length - 1].name}`);
        break;
      case 15:
        setHighlightedIndexes([]);
        setHighlightedIndexes2([4]);
        setLastPickMessage(`Picked ${selection[selection.length - 1].name}`);
        break;
      case 16:
        setBanIndex(-1);
        setBanIndex2(-1);
        setHighlightedIndexes([]);
        setHighlightedIndexes2([]);
        setLastPickMessage(`Picked ${selection[selection.length - 1].name}`);
        break;
      default:
        setBanIndex(-1);
        setBanIndex2(-1);
        setHighlightedIndexes([]);
        setHighlightedIndexes2([]);
        break;
    }
  }, [selection]);

  const putBackHeroInPool = (name: string) => {
    if (selection.length === 0 || name !== selection[selection.length - 1].name) return;

    // cho'gall exception: remove the other one too if one is removed (but only valid in team not in ban)
    if (name === "Cho" || name === "Gall") {
      if (choGallAutoUnpickPositions.includes(selection.length)) {
        selection.splice(-1, 1);
      }
    }

    selection.splice(-1, 1);
    setSelection([...selection]);
    setSuggestions(isMyTeamFirstPick, selection);
  };

  const addHero = (hero: IHero) => {
    if (isMyTeamFirstPick === null) return;
    if (selection.length === 16) return;

    // cho'gall exception
    let ChoGallBinome = null;
    if (hero.name === "Cho" || hero.name === "Gall") {
      const isNonePicked =
        selection.filter((h) => h.name === "Cho" || h.name === "Gall").length === 0;
      if (isNonePicked && choGallAutoPickIndexes.includes(selection.length)) {
        const index = heroesPool.findIndex(
          (h) => h.name === (hero.name === "Cho" ? "Gall" : "Cho")
        );
        if (index !== -1) ChoGallBinome = heroesPool[index];
      } else {
        if (
          !firstTeamBanIndexes.includes(selection.length) &&
          !secondTeamBanIndexes.includes(selection.length)
        )
          return;
      }
    }
    if (ChoGallBinome) {
      ChoGallBinome.pickOrder = selection.length + 1;
      selection.push(ChoGallBinome);
    }

    hero.pickOrder = selection.length + 1;
    selection.push(hero);
    setSuggestions(isMyTeamFirstPick, selection);
    setSelection([...selection]);
  };

  const handleBanClick = (isForMyTeam: boolean, name: string) => {
    if (selection.length === 0) setIsMyTeamFirstPick(isForMyTeam);
    if (!name) return;
    putBackHeroInPool(name);
  };

  const handleResetClick = () => {
    setSuggestions(isMyTeamFirstPick, []);
    setSelection([]);
    setBanIndex(-1);
    setBanIndex2(-1);
    setHighlightedIndexes([]);
    setHighlightedIndexes2([]);
  };

  const handleRevertClick = () => putBackHeroInPool(selection[selection.length - 1].name);

  const isLastSelected = (heroName: string) => {
    return selection.length > 0 && heroName === selection[selection.length - 1].name;
  };

  const myTeam: IHero[] = (isMyTeamFirstPick ? firstTeamIndexes : secondTeamIndexes).map(
    (index) => {
      const hero = selection[index];
      if (hero && hero.name) {
        hero.isLastSelected = isLastSelected(hero.name);
        return hero;
      }
      return {} as IHero;
    }
  );

  const opponentTeam: IHero[] = (!isMyTeamFirstPick ? firstTeamIndexes : secondTeamIndexes).map(
    (index) => {
      const hero = selection[index];
      if (hero && hero.name) {
        hero.isLastSelected = isLastSelected(hero.name);
        return hero;
      }
      return {} as IHero;
    }
  );

  const blueColor = "#14519c";
  const redColor = "#a7162d";
  return (
    <Container isDesktop={isDesktopBrowsing}>
      <MatchupActions
        selection={selection}
        isMyTeamFirstPick={isMyTeamFirstPick}
        isPickOrderDisplayed={isPickOrderDisplayed}
        lastPickMessage={lastPickMessage}
        handleBanClick={handleBanClick}
        handleResetClick={handleResetClick}
        handleRevertClick={handleRevertClick}
        onBlueFirstPickClick={() => setIsMyTeamFirstPick(true)}
        onRedFirstPickClick={() => setIsMyTeamFirstPick(false)}
        onPickOrderDisplayChange={(e) => setIsPickOrderDisplayed(e.target.checked)}
      />
      <LinkContainer>
        {selection.length === 16 && <Link href={matchupUrl}>{matchupUrl}</Link>}
        {selection.length < 16 && currentAction && <div>{currentAction}</div>}
      </LinkContainer>
      <MatchupHeader
        selection={selection}
        isMyTeamFirstPick={isMyTeamFirstPick}
        banIndex={banIndex}
        banIndex2={banIndex2}
        lastPickMessage={lastPickMessage}
        currentAction={currentAction}
        isPickOrderDisplayed={isPickOrderDisplayed}
        handleBanClick={handleBanClick}
        handleResetClick={handleResetClick}
        handleRevertClick={handleRevertClick}
        onBlueFirstPickClick={() => setIsMyTeamFirstPick(true)}
        onRedFirstPickClick={() => setIsMyTeamFirstPick(false)}
      />
      <MatchupContentContainer className="matchup-content" isDesktop={isDesktopBrowsing}>
        <BlueTeamContainer className="team-blue" isDesktop={isDesktopBrowsing}>
          <Team
            heroes={myTeam}
            onClick={putBackHeroInPool}
            teamColor={blueColor}
            highlightedIndexes={isMyTeamFirstPick ? highlightedIndexes : highlightedIndexes2}
            isPickOrderDisplayed={isPickOrderDisplayed}
          />
        </BlueTeamContainer>
        <HeroPoolContainer className="matchup-hero-pool" isDesktop={isDesktopBrowsing}>
          <HeroPool
            heroes={heroesPool}
            onClick={(hero) => {
              if (selection.map((h) => h.name).includes(hero.name)) {
                if (selection[selection.length - 1].name === hero.name) {
                  putBackHeroInPool(hero.name);
                }
                return;
              }

              addHero(hero);
            }}
            suggestions={suggestions}
            disabledHeroes={selection.map((h) => h.name)}
          />
        </HeroPoolContainer>
        <RedTeamContainer className="team-red" isDesktop={isDesktopBrowsing}>
          <Team
            heroes={opponentTeam}
            onClick={putBackHeroInPool}
            isMirrored
            teamColor={redColor}
            highlightedIndexes={!isMyTeamFirstPick ? highlightedIndexes : highlightedIndexes2}
            isPickOrderDisplayed={isPickOrderDisplayed}
          />
        </RedTeamContainer>
      </MatchupContentContainer>
    </Container>
  );
}
