import * as React from "react";

interface IProps {
  tierTalentCount: number;
  pickIndex: number;
  imageUrl: string;
}

export default function TalentPick(props: IProps) {
  const generateSquares = () => {
    const squares = [];
    for (var i = 0; i < props.tierTalentCount; i++) {
      squares.push(
        <div
          style={{
            width: "15px",
            height: "15px",
            backgroundColor: i === props.pickIndex ? "green" : "grey",
            marginBottom: "2px",
          }}
        />
      );
    }
    return squares.map((s) => s);
  };

  return (
    <>
      <div style={{ display: "flex", marginRight: "10px" }}>
        <div style={{ marginRight: "2px" }}>{generateSquares()}</div>
        <div style={{ width: "128px" }}>
          <img src={props.imageUrl} alt="talent" />
        </div>
      </div>
    </>
  );
}
