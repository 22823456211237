import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import UndoIcon from "@material-ui/icons/Undo";
import styled, { css } from "styled-components";

import { IHero, IResponsiveContainer } from "../../tools/interfaces";
import TeamBan from "./TeamBan";
import MatchupActions from "./MatchupActions";
import { useMedia } from "@gamate/common";

interface IProps {
  selection: IHero[];
  isMyTeamFirstPick: boolean;
  banIndex: number;
  banIndex2: number;
  lastPickMessage: string;
  currentAction: string;
  isPickOrderDisplayed: boolean;

  handleBanClick: (isForMyTeam: boolean, name: string) => void;
  handleResetClick: () => void;
  handleRevertClick: () => void;

  onBlueFirstPickClick: () => void;
  onRedFirstPickClick: () => void;
}

const Container = styled.div<IResponsiveContainer>`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: ${(props) => (props.isDesktop ? "220px" : "35px")};
  ${(props) =>
    props.isDesktop &&
    css`
      margin-bottom: 15px;
    `}
`;

const BlueBanContainer = styled.div``;
const RedBanContainer = styled.div``;

const blueColor = "#14519c";
const redColor = "#a7162d";

export default function MatchupHeader(props: IProps) {
  const { isDesktopBrowsing } = useMedia();

  return (
    <Container isDesktop={isDesktopBrowsing}>
      <BlueBanContainer className="ban-blue">
        <TeamBan
          selection={props.selection}
          isFirstPick={props.isMyTeamFirstPick}
          onClick={(heroName: string) => props.handleBanClick(true, heroName)}
          selectedIndex={props.isMyTeamFirstPick ? props.banIndex : props.banIndex2}
          teamColor={blueColor}
          isPickOrderDisplayed={props.isPickOrderDisplayed}
        />
      </BlueBanContainer>
      <RedBanContainer className="ban-red">
        <TeamBan
          selection={props.selection}
          isFirstPick={!props.isMyTeamFirstPick}
          onClick={(heroName: string) => props.handleBanClick(false, heroName)}
          selectedIndex={!props.isMyTeamFirstPick ? props.banIndex : props.banIndex2}
          teamColor={redColor}
          isPickOrderDisplayed={props.isPickOrderDisplayed}
        />
      </RedBanContainer>
    </Container>
  );
}
