import { useMedia } from "@gamate/common";
import styled from "styled-components";
import { IHero, IResponsiveContainer } from "../../../tools/interfaces";

interface IProps {
  hero: IHero;
  isDisabled: boolean;
  suggestions: string[];

  onClick: () => void;
}

const Container = styled.div<IResponsiveContainer>`
  border: 3px solid #90bff5;
  border-radius: 38px;
  width: ${(props) => (props.isDesktop ? "70px" : "40px")};
  height: ${(props) => (props.isDesktop ? "70px" : "40px")};
`;

const Img = styled.img<IResponsiveContainer>`
  width: ${(props) => (props.isDesktop ? "70px" : "40px")};
  cursor: pointer;
`;

export default function HeroCircle(props: IProps) {
  const { isDesktopBrowsing } = useMedia();

  return (
    <Container
      isDesktop={isDesktopBrowsing}
      key={props.hero.name}
      onClick={props.onClick}
      className={`hero-circle ${props.isDisabled ? "hero-circle-disabled" : ""} ${
        props.suggestions.includes(props.hero.name) && !props.isDisabled
          ? "hero-circle-suggestion"
          : ""
      }`}
    >
      <Img src={props.hero.circleImgUrl} alt={props.hero.name} isDesktop={isDesktopBrowsing} />
    </Container>
  );
}
