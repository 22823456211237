import * as React from 'react'

import './Hexagon.css'

interface IProps extends React.PropsWithChildren<any> {
    imgUrl?: string
    isHighlighted?: boolean
    size: "small" | "medium" | "large"
    borderColor?: string
}

function Hexagon(props: IProps) {
    const styleImg = props.imgUrl ? { backgroundImage: `url(${props.imgUrl})` } : {}
    const hexagonClassNames = `hexagon ${props.size}`
    const borderClassName = `${props.size}-border${props.isHighlighted ? "-hightlighted" : ""}`
    return (
        <div className={`${props.size}-container`}>
            <div className={hexagonClassNames} style={{ ...styleImg, textAlign: "center" }}>
                {props.children}
            </div>
            <div className={borderClassName} style={{ backgroundColor: props.borderColor }}></div>
        </div >
    )
}

Hexagon.defaultProps = {
    imgUrl: "",
    isHighlighted: false,
    size: "medium",
    borderColor: ""
}

export default Hexagon