import { useMedia } from "@gamate/common";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Switch,
} from "@material-ui/core";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import UndoIcon from "@material-ui/icons/Undo";
import { ChangeEvent } from "react";
import styled from "styled-components";

import { IHero, IResponsiveContainer } from "../../tools/interfaces";

interface IProps {
  selection: IHero[];
  isMyTeamFirstPick: boolean;
  lastPickMessage: string;
  isPickOrderDisplayed: boolean;

  handleBanClick: (isForMyTeam: boolean, name: string) => void;
  handleResetClick: () => void;
  handleRevertClick: () => void;

  onBlueFirstPickClick: () => void;
  onRedFirstPickClick: () => void;

  onPickOrderDisplayChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Container = styled.div`
  height: 140px;
`;

const ActionLineContainer = styled.div<IResponsiveContainer>`
  display: flex;
  height: ${(props) => (props.isDesktop ? "35px" : "50px")};
  align-items: center;
`;

const LabelContainer = styled.span`
  width: 50%;
  display: flex;
  justify-content: end;
`;

export default function MatchupActions(props: IProps) {
  const { isDesktopBrowsing } = useMedia();

  const handleFirstPickChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    switch (value) {
      case "blue":
        props.onBlueFirstPickClick();
        break;
      case "red":
        props.onRedFirstPickClick();
        break;
    }
  };

  return (
    <Container>
      {isDesktopBrowsing && (
        <ActionLineContainer isDesktop={isDesktopBrowsing}>
          <LabelContainer>Display pick order</LabelContainer>
          <Switch
            color="primary"
            checked={props.isPickOrderDisplayed}
            onChange={props.onPickOrderDisplayChange}
          />
        </ActionLineContainer>
      )}
      <ActionLineContainer isDesktop={isDesktopBrowsing}>
        <LabelContainer>First pick</LabelContainer>
        <FormControl style={{ paddingLeft: "12px", width: "50%" }}>
          <RadioGroup
            row
            value={props.isMyTeamFirstPick ? "blue" : "red"}
            onChange={handleFirstPickChange}
          >
            <FormControlLabel
              value="blue"
              control={<Radio color="primary" disabled={props.selection.length > 0} />}
              label="Blue team"
            />
            <FormControlLabel
              value="red"
              control={<Radio color="secondary" disabled={props.selection.length > 0} />}
              label="Red team"
            />
          </RadioGroup>
        </FormControl>
      </ActionLineContainer>
      {props.selection.length > 0 && (
        <>
          <ActionLineContainer isDesktop={isDesktopBrowsing}>
            <LabelContainer>{props.lastPickMessage}</LabelContainer>
            <IconButton>
              <UndoIcon onClick={props.handleRevertClick} />
            </IconButton>
          </ActionLineContainer>
          <ActionLineContainer isDesktop={isDesktopBrowsing}>
            <LabelContainer>Reset</LabelContainer>
            <IconButton>
              <CancelPresentationIcon onClick={props.handleResetClick} />
            </IconButton>
          </ActionLineContainer>
        </>
      )}
    </Container>
  );
}
