import * as React from 'react'

import RoleIcon, { IRoleIconProps } from './RoleIcon'

function TankIcon(props: IRoleIconProps) {
    return (
        <RoleIcon title="Tank" onClick={props.onClick} isActive={props.isActive}>
            <path d="M108,202.08S37,163,40.42,68.7l9.78-7V30.46a69.52,69.52,0,0,0,30.28,0C96.9,26.73,108,14.13,108,14.13s11,12.6,27.43,16.33a69.26,69.26,0,0,0,30.22,0V61.7l9.76,7C178.83,163,108,202.08,108,202.08ZM166.07,74.76l-8.83-7v-27a82.1,82.1,0,0,1-24.64-1.87C119.47,35.76,108,26.26,108,26.26s-11.53,9.5-24.69,12.59a82.36,82.36,0,0,1-24.68,1.87v27l-8.85,7S47,149.43,108,190C168.85,149.43,166.07,74.76,166.07,74.76Zm-33,24.72c0-6.88,3.26-9.33,3.26-9.33l16.24-16.29,5.61,4.63c.34,33.27-12.38,59-25.11,76.43Zm2.33-12.59a16.81,16.81,0,0,0-5.12,12.59v59.13C118.72,173.37,108,181.09,108,181.09s-10.78-7.72-22.36-22.48V99.48a16.81,16.81,0,0,0-5.12-12.59C76.23,83,68.93,75.49,65.54,72l0,0V47.71S89.18,50.29,108,34.65c18.75,15.64,42.31,13.06,42.31,13.06V72l0,0C146.92,75.49,139.63,83,135.39,86.89ZM82.81,99.48v55.44c-12.75-17.39-25.5-43.16-25.16-76.43l5.62-4.63L79.55,90.15S82.81,92.6,82.81,99.48Z"></path>
        </RoleIcon>
    )
}

export default TankIcon