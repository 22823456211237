import * as React from 'react'

function Event() {
    const america = [
        { label: "Nexus Gaming Series", url: "https://www.nexusgamingseries.org/" },
        { label: "HeroesHearth  Community Clash League", url: "https://heroeshearth.com/b/workhorse/read/everything-you-need-to-know-about-ccl/" },
    ]

    const asia = [
        { label: "2020 HOTS Discord Invincible League", url: "https://www.twitch.tv/hyeok9309" }
    ]

    const china = [
        { label: "Gold Series", url: "http://gold.blizzard.cn/minisite/heroes-tournament" }
    ]

    // https://www.nexuscontest.eu/
    const europe = [
        { label: "Heroes Lounge", url: "https://heroeslounge.gg/" },
        { label: "Nexus Conflict", url: "https://www.join-elo.it/info-nexus-conflict/" }
    ]

    const casters = [
        "https://www.twitch.tv/heroes_lounge",
        "https://www.twitch.tv/reborn20200710",
    ]

    const generateTournamentLines = (arr: any[]) => {
        return arr.map((t, index) => {
            const label = t.label ? t.label : t.url
            return (
                <li key={index}>
                    {t.url &&
                        <a href={t.url} target="_blank" rel="noopener noreferrer">{label}</a>
                    }
                    {!t.url &&
                        label
                    }
                </li>
            )
        })
    }

    const generateCasterLines = (arr: string[]) => {
        return arr.map((url, index) => <li key={index}><a href={url} target="_blank" rel="noopener noreferrer">{url}</a></li>)
    }

    return (
        <>
            <div style={{ textDecoration: "underline" }}>Regular tournaments</div>
            <div>America</div>
            <ul>
                {generateTournamentLines(america)}
            </ul>
            <div>Asia</div>
            <ul>
                {generateTournamentLines(asia)}
            </ul>
            <div>China</div>
            <ul>
                {generateTournamentLines(china)}
            </ul>
            <div>Europe</div>
            <ul>
                {generateTournamentLines(europe)}
            </ul>

            <div style={{ textDecoration: "underline" }}>Event casters</div>
            <ul>
                {generateCasterLines(casters)}
            </ul>
        </>
    )
}

export default Event

