import { useState, useEffect } from "react";
import styled from "styled-components";

import { arrDistinct } from "@gamate/common";
import { TierListPage } from "@gamate/components";

import { IHero } from "../../tools/interfaces";
import { heroService, tierlistService } from "../../tools/services";

const Container = styled.div`
  width: 100%;
  margin-top: 10px;
`;

const selectList = (list: any) => {
  list.isSelected = !list.isDisabled;
};

export default function TierList() {
  const [selectedLists, setSelectedLists] = useState([] as any[]);

  const [heroes, setHeroes] = useState([] as string[]);
  const [roles, setRoles] = useState([] as string[]);

  useEffect(() => {
    (async function () {
      const tierlists = await tierlistService.getAll();
      tierlists.forEach(selectList);
      setSelectedLists(tierlists.filter((l: any) => !l.isDisabled));

      const heroes = await heroService.getAll();
      setHeroes(heroes.map((h: IHero) => h.name));
      setRoles(arrDistinct(heroes.map((h: IHero) => h.role)).sort());
    })();
  }, []);

  return (
    <Container>
      <TierListPage tierLists={selectedLists} heroes={heroes} roles={roles} />
    </Container>
  );
}
