import * as React from "react";
import { IHero } from "../../tools/interfaces";
import { buildService, heroService } from "../../tools/services";
import FilterBar from "../Matchup/FilterBar";

import HeroBuild from "./HeroBuild";

export default function Build() {
  const [heroes, setHeroes] = React.useState([] as IHero[]);
  const [builds, setBuilds] = React.useState([] as any[]);

  const [selectedHeroes, setSelectedHeroes] = React.useState([] as IHero[]);
  const [roleFilter, setRoleFilter] = React.useState("");
  const [nameFilter, setNameFilter] = React.useState("");

  React.useEffect(() => {
    (async () => {
      setHeroes(await heroService.getAll("forbuild"));
      setBuilds(await buildService.getAll());
    })();
  }, []);

  React.useEffect(() => {
    if (roleFilter === "" && nameFilter === "") {
      setSelectedHeroes(heroes);
      return;
    }

    let preFiltered = heroes;
    if (roleFilter !== "")
      preFiltered = preFiltered.filter((h) => h.role === roleFilter);
    if (nameFilter !== "")
      preFiltered = preFiltered.filter((h) =>
        h.name?.toLocaleLowerCase().includes(nameFilter.toLocaleLowerCase())
      );
    setSelectedHeroes(preFiltered);
  }, [heroes, roleFilter, nameFilter]);

  const handleRoleFilterClick = (value: string) => {
    if (value === roleFilter) setRoleFilter("");
    else setRoleFilter(value);
  };

  const handleNameFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNameFilter(e.target.value);
  };

  const handleNameFilterKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Escape") setNameFilter("");
  };

  const generateBuilds = () => {
    const elts: any[] = [];
    selectedHeroes.forEach((hero) => {
      const heroFound = builds.filter((build) => build.code === hero.code)[0];
      if (heroFound === undefined) return;
      elts.push(<HeroBuild {...heroFound} />);
    });
    return elts;
  };

  return (
    <>
      <div>builds for version 2.54.4.86223</div>
      <div>
        <FilterBar
          roleFilter={roleFilter}
          nameFilter={nameFilter}
          onRoleClick={handleRoleFilterClick}
          onTextChange={handleNameFilterChange}
          onKeyDown={handleNameFilterKey}
        />
      </div>

      <div>{generateBuilds()}</div>
    </>
  );
}
