import * as React from "react";
import styled from "styled-components";

import { useMedia } from "@gamate/common";

import BruiserIcon from "../../../components/RoleIcon/BruiserIcon";
import HealerIcon from "../../../components/RoleIcon/HealerIcon";
import MeleeAssassinIcon from "../../../components/RoleIcon/MeleeAssassinIcon";
import RangedAssassinIcon from "../../../components/RoleIcon/RangedAssassinIcon";
import SupportIcon from "../../../components/RoleIcon/SupportIcon";
import TankIcon from "../../../components/RoleIcon/TankIcon";
import RoleButton from "./RoleButton";

interface IProps {
  roleFilter?: string;
  nameFilter?: string;
  onRoleClick: (role: string) => void;
  onTextChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HexagonsContainer = styled.div`
  display: flex;
  margin-top: 15px;
  margin-bottom: 10px;
`;

const TextFilterContainer = styled.div`
  height: 32px;
  border: 1px solid;
  border-radius: 4px;
  display: flex;
`;

function FilterBar(props: IProps) {
  const { isDesktopBrowsing } = useMedia();

  const handleRoleFilterClick = (e: React.MouseEvent, value: string) => {
    e.preventDefault();
    props.onRoleClick(value);
  };

  const handleNameFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onTextChange(e);
  };

  const handleNameFilterKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    props.onKeyDown(e);
  };

  return (
    <Container>
      {isDesktopBrowsing && (
        <TextFilterContainer>
          <input
            type="text"
            onChange={handleNameFilterChange}
            onKeyDown={handleNameFilterKey}
            value={props.nameFilter}
            style={{ border: "0", outline: "0", marginLeft: "1px", display: "block" }}
          />
          <svg
            viewBox="0 0 24 24"
            width="30"
            height="30"
            focusable="false"
            aria-hidden="true"
            tabIndex={-1}
            style={{ pointerEvents: "none" }}
          >
            <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
          </svg>
        </TextFilterContainer>
      )}
      <HexagonsContainer>
        <RoleButton>
          <TankIcon
            onClick={(e) => handleRoleFilterClick(e, "tank")}
            isActive={props.roleFilter === "tank"}
          />
        </RoleButton>
        <RoleButton>
          <BruiserIcon
            onClick={(e) => handleRoleFilterClick(e, "bruiser")}
            isActive={props.roleFilter === "bruiser"}
          />
        </RoleButton>
        <RoleButton>
          <SupportIcon
            onClick={(e) => handleRoleFilterClick(e, "support")}
            isActive={props.roleFilter === "support"}
          />
        </RoleButton>
        <RoleButton>
          <HealerIcon
            onClick={(e) => handleRoleFilterClick(e, "healer")}
            isActive={props.roleFilter === "healer"}
          />
        </RoleButton>
        <RoleButton>
          <RangedAssassinIcon
            onClick={(e) => handleRoleFilterClick(e, "ranged-assassin")}
            isActive={props.roleFilter === "ranged-assassin"}
          />
        </RoleButton>
        <RoleButton>
          <MeleeAssassinIcon
            onClick={(e) => handleRoleFilterClick(e, "melee-assassin")}
            isActive={props.roleFilter === "melee-assassin"}
          />
        </RoleButton>
      </HexagonsContainer>
    </Container>
  );
}

FilterBar.defaultProps = {
  onRoleClick: (role: string) => {},
  onTextChange: (e: React.ChangeEvent<HTMLInputElement>) => {},
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {},
};

export default FilterBar;
