import * as React from 'react'

import RoleIcon, { IRoleIconProps } from './RoleIcon'

function RangedAssassinIcon(props: IRoleIconProps) {
    return (
        <RoleIcon title="Ranged Assassin" onClick={props.onClick} isActive={props.isActive}>
            <path d="M203.13,27.23,184.78,65.86s.25,32.68-16.43,54l3.88,22.81L151.54,135a109.08,109.08,0,0,1-31,13.92V132.48s21.71-2.58,37.18-24.62,11.59-47.32,11.59-47.32ZM123,53.37,136,40.25V58L123,71.07v5l8.27-8.29V83L113.8,100.63v69.05c14.89,0,20.21-5.79,20.21-5.79L108,209.2v0l0,0v-.05L81.92,163.87s5.34,5.79,20.29,5.79V100.58L84.73,83V67.69L93,76V71L79.9,57.88V40.17L93,53.3V48.47l-18-18.11V12.65L108,45.58v.23l0-.16,32.81-32.91v17.7L123,48.55ZM95.44,148.9a109.08,109.08,0,0,1-31-13.92l-20.69,7.64,3.88-22.81C31,98.54,31.22,65.86,31.22,65.86L12.87,27.23l33.8,33.31s-3.88,25.28,11.59,47.32,37.18,24.62,37.18,24.62Z"></path>
        </RoleIcon>
    )
}

export default RangedAssassinIcon