import { useCallback, useMemo } from "react";

import { useMedia } from "@gamate/common";
import { IColumn, SelectColumnFilter, TextColumnFilter } from "@gamate/components";

import Link from "../../components/Link";
import { IRow } from "./contracts";

export const useColumns = (
  handleBattletagClick: (e: any, streamUrl: string) => void
): IColumn<any>[] => {
  const filterName = useCallback((rows: any[], columnIds: string[], filterValue: string) => {
    const lowerCaseValue = filterValue.toLocaleLowerCase();
    return rows.filter((r) => {
      const lowerCaseBattleTag = r.original.battletag?.toLocaleLowerCase();
      const lowerCaseStreamUrl = r.original.streamUrl?.toLocaleLowerCase();
      return (
        lowerCaseBattleTag?.includes(lowerCaseValue) || lowerCaseStreamUrl?.includes(lowerCaseValue)
      );
    });
  }, []);

  const { isDesktopBrowsing } = useMedia();

  return useMemo(() => {
    const globalRankCol = {
      label: isDesktopBrowsing ? "Global rank" : "Gbl",
      accessor: (r: any) => r.globalRank,
    };
    const regionRankCol = {
      label: isDesktopBrowsing ? "Region rank" : "Reg",
      accessor: (r: any) => r.rank,
    };
    const battleTagCol = {
      label: "Battletag",
      accessor: (r: any) => r.battletag,
      Cell: ({ row }: any) => {
        const typedRow: IRow = row.original;
        if (typedRow.streamUrl) {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Link
                href={typedRow.streamUrl}
                onClick={(e: any) => {
                  if (isDesktopBrowsing) handleBattletagClick(e, typedRow.streamUrl);
                }}
                rel="noopener noreferrer"
                target="_blank"
                style={{ display: "flex" }}
              >
                <span>{typedRow.battletag}</span>
                {typedRow.streamLogoUrl && (
                  <img
                    src={typedRow.streamLogoUrl}
                    alt="stream logo"
                    style={{ marginLeft: "5px" }}
                  />
                )}
              </Link>
              {typedRow.isLive && <span className="live-status">LIVE</span>}
            </div>
          );
        }
        return <>{typedRow.battletag}</>;
      },
      Filter: TextColumnFilter,
      filter: filterName,
    };
    const regionCol = {
      label: "Region",
      accessor: (r: any) => r.region,
      Filter: SelectColumnFilter,
      filter: "includes",
    };

    if (!isDesktopBrowsing) {
      return [globalRankCol, battleTagCol, regionCol];
    }

    return [
      globalRankCol,
      regionRankCol,
      battleTagCol,
      { label: "Points", accessor: (r: any) => r.points },
      { label: "Games", accessor: (r: any) => r.gameCount },
      { label: "Wins", accessor: (r: any) => r.winCount },
      { label: "W/L ratio", accessor: (r: any) => r.ratio },
      {
        label: "Main role",
        accessor: (r: any) => r.mainRole,
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      { label: "Main role pick ratio", accessor: (r: any) => r.mainRolePickPercent },
      regionCol,
    ];
  }, [handleBattletagClick, filterName, isDesktopBrowsing]);
};
