import * as React from 'react'

import RoleIcon, { IRoleIconProps } from './RoleIcon'

function HealerIcon(props: IRoleIconProps) {
    return (
        <RoleIcon title="Healer" onClick={props.onClick} isActive={props.isActive}>
            <path d="M171.77,132.69H132.69v39.08c0,3.41-4.82,8.23-8.23,8.23H91.54c-3.41,0-8.23-4.82-8.23-8.23V132.69H44.23c-3.41,0-8.23-4.82-8.23-8.23V91.54c0-3.41,4.82-8.23,8.23-8.23H83.31V44.23c0-3.41,4.82-8.23,8.23-8.23h32.92c3.41,0,8.23,4.82,8.23,8.23V83.31h39.08c3.41,0,8.23,4.82,8.23,8.23v32.92C180,127.87,175.18,132.69,171.77,132.69Z"></path>
        </RoleIcon>
    )
}

export default HealerIcon