import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import styled from "styled-components";

import { useMedia } from "@gamate/common";

import { IHero, IResponsiveContainer } from "../../../tools/interfaces";
import FilterBar from "../FilterBar";
import HeroCircle from "./HeroCircle";

interface IProps {
  heroes: IHero[];
  onClick: (hero: IHero) => void;
  suggestions: string[];
  disabledHeroes: string[];
}

const PoolContainer = styled.div<IResponsiveContainer>`
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin: auto;
  max-height: ${(props) => (props.isDesktop ? "420px" : "210px")};
  width: ${(props) => (props.isDesktop ? "340px" : "190px")};
`;

function HeroPool(props: IProps) {
  const [heroes, setHeroes] = useState([] as IHero[]);
  const [roleFilter, setRoleFilter] = useState("");
  const [nameFilter, setNameFilter] = useState("");

  const { isDesktopBrowsing } = useMedia();

  useEffect(() => {
    setHeroes(props.heroes);
  }, [props.heroes]);

  useEffect(() => {
    if (roleFilter === "" && nameFilter === "") setHeroes(props.heroes);

    let preFiltered = props.heroes;
    if (roleFilter !== "") preFiltered = preFiltered.filter((h) => h.role === roleFilter);
    if (nameFilter !== "")
      preFiltered = preFiltered.filter((h) =>
        h.name.toLocaleLowerCase().includes(nameFilter.toLocaleLowerCase())
      );
    setHeroes(preFiltered);
  }, [props.heroes, roleFilter, nameFilter]);

  const handleRoleFilterClick = (value: string) => {
    if (value === roleFilter) setRoleFilter("");
    else setRoleFilter(value);
  };

  const handleNameFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNameFilter(e.target.value);
  };

  const handleNameFilterKey = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Escape") setNameFilter("");
  };

  return (
    <>
      <FilterBar
        roleFilter={roleFilter}
        nameFilter={nameFilter}
        onRoleClick={handleRoleFilterClick}
        onTextChange={handleNameFilterChange}
        onKeyDown={handleNameFilterKey}
      />
      <PoolContainer className="hero-pool" isDesktop={isDesktopBrowsing}>
        {heroes.map((hero) => {
          const isDisabled = props.disabledHeroes.includes(hero.name);
          return (
            <HeroCircle
              key={hero.name}
              hero={hero}
              isDisabled={isDisabled}
              suggestions={props.suggestions}
              onClick={() => props.onClick(hero)}
            />
          );
        })}
      </PoolContainer>
    </>
  );
}

HeroPool.defaultProps = {
  heroes: [],
  onClick: () => {},
  suggestions: [],
  disabledHeroes: [],
};

export default HeroPool;
