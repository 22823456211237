import * as React from 'react'

import RoleIcon, { IRoleIconProps } from './RoleIcon'

function BruiserIcon(props: IRoleIconProps) {
    return (
        <RoleIcon title="Bruiser" onClick={props.onClick} isActive={props.isActive}>
            <path d="M163.12,143.75l-4.92-13.33s-10.43-4.12-20.66-6.91c0,0,3.34-7.05,5.41-12.34,0,0,11.14,2.75,13.78,4A109.66,109.66,0,0,0,159.19,87S140.56,70.7,103,70.7,47.53,87,47.53,87s.37,13.26,2,21.22l3-1a176.92,176.92,0,0,0,2.83,17.56L49,127.08,43.6,143.26s-16.4-40-9.35-112c0,0,7.87,19.81,20.17,32.08,0,0,6.78-4.18,23.12-6.91L103.11,5.06l25.58,51.33a113.66,113.66,0,0,1,24.1,6.91,127.06,127.06,0,0,0,19.68-32.08S179.65,92.24,163.12,143.75Zm-96-43.19-.48-9s5.84-5.36,9.5-5.7,9,2.38,9,2.38a129.5,129.5,0,0,1,0,14.25L74.28,99.61Zm72.19-10.45c2.92.27,7.57,7.11,8.08,20l-6.65-2.38s-4,14.87-8.55,15.68-10.93-1.42-10.93-1.42a169.81,169.81,0,0,0,10.45-29S136.43,89.83,139.35,90.11Zm-18.52-4.76c2.73.43,7.6,5.71,7.6,5.71s-5.65,20.5-9,26.6c-2.79,5-12.05,1.41-14.25.48,0,0,4.44-18.24,6.18-29.93C111.33,88.21,117.57,84.85,120.83,85.35ZM98.5,83.45c3.53-.06,8.55,3.81,8.55,3.81a103.28,103.28,0,0,1-2.85,17.57S95,105,89,103.41c0,0,.36-8,0-14.73C89,88.68,95,83.52,98.5,83.45ZM72.62,103.77c3.53.23,13.42,3.32,20.66,4a82.53,82.53,0,0,0,9.76.49s.64,6.6-5.34,13.33c0,0-6.19,3.82-15.74,3s-15.24-8.88-15.24-8.88S71.83,126.34,77,127.46s17.36-.56,21.15.5,8.81,4.31,22.14,20.73c0,0-5-8.86-10-15.83a61.54,61.54,0,0,0-7.13-8.07,211.84,211.84,0,0,1,21.38,2.37c9.86,1.68,19.66,4.58,20.9,5.23,0,0-1.9,16.15-3.33,19s-8.1,9.14-8.1,9.14v9.87A179.28,179.28,0,0,1,108,185.7a167.69,167.69,0,0,1-26.56-15.3V162l-10.33-5.92s-1.7-.37-3.44-4S58,133.86,55.89,106.24C55.89,106.24,69.08,103.54,72.62,103.77Zm7.84,78a27.64,27.64,0,0,0,1-8.45C93,181.49,108,188.15,108,188.15c10.41-4.7,26.07-14.8,26.07-14.8a29.86,29.86,0,0,0,1.45,8,19.18,19.18,0,0,0,3.8,6.17C129.22,195.94,108,208.41,108,208.41c-13.51-7.23-31.37-20.91-31.37-20.91A13.46,13.46,0,0,0,80.46,181.8Z"></path>
        </RoleIcon>
    )
}

export default BruiserIcon