import { config } from "../config";

export const streamService = {
  getAll: async () => {
    return fetch(`${config.API_URL}/v0/lives`)
      .then((resp) => resp.json())
      .catch(() => []);
  },

  getStreamLogoUrl: (streamUrl: string) => {
    let match = streamUrl.match(/www.twitch.tv\/(.*)/);
    if (match !== null) return "twitch20.png";
    match = streamUrl.match(/www.douyu.com\/(.*)/);
    if (match !== null) return "douyu20.png";
    return undefined;
  },
};
