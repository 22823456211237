import { PropsWithChildren } from "react";
import styled from "styled-components";
import Hexagon from "../Hexagon";

interface IProps extends PropsWithChildren<any> {
}

const IconContainer = styled.div`
  padding-top: 3px;
  padding-right: 1px;
`;

export default function RoleButton(props: IProps) {
  return (
    <Hexagon size="small">
      <IconContainer>{props.children}</IconContainer>
    </Hexagon>
  );
}
