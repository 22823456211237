import { IHero } from "../interfaces";
import { config } from "../config";

export { buildService } from "./buildservice";
export { streamService } from "./streamservice";

export const heroService = {
  getAll: async (set?: string): Promise<IHero[]> => {
    const heroesJson = await fetch(
      `${config.API_URL}/v0/heroes${set !== undefined ? `?set=${set}` : ""}`
    )
      .then((resp) => resp.text())
      .then((data) => JSON.parse(data))
      .catch(() => []);
    heroesJson.sort((a: IHero, b: IHero) => {
      if (a.name === b.name) return a.code > b.code ? 1 : -1;
      return a.name > b.name ? 1 : -1;
    });
    return heroesJson;
  },
  getNames: async () => {
    const names = await fetch(`${config.API_URL}/v0/heroes/?set=name`)
      .then((resp) => resp.text())
      .then((data) => JSON.parse(data))
      .catch(() => []);
    names.sort();
    return names;
  },
};

export const leaderBoardService = {
  getAll: async () => {
    return fetch(`${config.API_URL}/v0/leaderboard`)
      .then((resp) => resp.text())
      .then((data) => JSON.parse(data))
      .catch(() => []);
  },
  getAllStreams: async () => {
    return fetch(`${config.API_URL}/v0/streams`)
      .then((resp) => resp.text())
      .then((data) => JSON.parse(data))
      .catch(() => []);
  },
};

export const tierlistService = {
  getAll: async () => {
    return fetch(`${config.API_URL}/v1/tierlists`)
      .then((resp) => resp.json())
      .catch(() => []);
  },
};
