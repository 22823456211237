import { useMedia } from "@gamate/common";
import styled from "styled-components";
import Hexagon from "./Hexagon";

import "./TeamBan.css";

const Container = styled.div`
  display: flex;
  /* flex-direction: ${(props) => (props.isDesktop ? "row" : "column")}; */
`;

function TeamBan(props) {
  const { isDesktopBrowsing } = useMedia();

  const getTeamBan = (isFirstPick, index) => {
    if (isFirstPick) {
      switch (index) {
        case 0:
          return props.selection[0] ? props.selection[0] : `ban${index + 1}`;
        case 1:
          return props.selection[2] ? props.selection[2] : `ban${index + 1}`;
        case 2:
          return props.selection[10] ? props.selection[10] : `ban${index + 1}`;
        default:
          return "";
      }
    } else {
      switch (index) {
        case 0:
          return props.selection[1] ? props.selection[1] : `ban${index + 1}`;
        case 1:
          return props.selection[3] ? props.selection[3] : `ban${index + 1}`;
        case 2:
          return props.selection[9] ? props.selection[9] : `ban${index + 1}`;
        default:
          return "";
      }
    }
  };

  const getBan = (isFirstPick) => {
    return [0, 1, 2].map((index) => {
      const hero = getTeamBan(isFirstPick, index);
      const defaultStyle = { marginRight: "2px" };
      const style = hero.name ? { ...defaultStyle, cursor: "pointer" } : defaultStyle;
      return (
        <div
          key={`teamBan_${isFirstPick}_${index}`}
          style={style}
          onClick={() => props.onClick(hero.name)}
        >
          <Hexagon
            imgUrl={hero.circleImgUrl}
            isHighlighted={props.selectedIndex === index}
            size={isDesktopBrowsing ? "medium" : "small"}
            borderColor={props.teamColor}
          />
          {props.isPickOrderDisplayed && (
            <div style={{ height: 0, position: "relative", top: "-20px", left: "65px" }}>
              {hero.pickOrder}
            </div>
          )}
        </div>
      );
    });
  };

  return <Container isDesktop={isDesktopBrowsing}>{getBan(props.isFirstPick)}</Container>;
}

export default TeamBan;
