import * as React from 'react'
import { IHero } from '../../tools/interfaces'

export function useSuggestions(): [string[], (isFirstPick: boolean, selection: IHero[]) => void] {

    const [suggestions, innerSetSuggestions] = React.useState([] as string[])

    const setSuggestions = (isFirstPick: boolean, selection: IHero[]) => {

        // const teamIndexes = isFirstPick ? firstTeamIndexes : secondTeamIndexes

        // banning phase
        // if (selection.length < 4) {
        // innerSetSuggestions(["Cassia", "Tassadar", "Kael'thas", "Imperius"])
        // }
        // else {
        //     innerSetSuggestions([])
        // }
        innerSetSuggestions([])
    }
    return [suggestions, setSuggestions]
}