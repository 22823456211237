import { useEffect, useState } from 'react'
import { Switch } from '@material-ui/core';
import styled from 'styled-components';

import { useMedia } from '@gamate/common';
import { LoadingIcon, SimpleTable } from '@gamate/components';

import { useColumns } from './customHooks'
import { leaderBoardService, streamService } from '../../tools/services'
import Link from '../../components/Link';

import "./LeaderBoard.css"

const HeaderInfoContainer = styled.span`
margin-right: 5px;
display: inline-block;
`
const DateContainer = styled.span`
white-space:nowrap;
`

const MobileTableContainer = styled.table`
width: 100%;
margin-left: -20px;
`

const TableRowContainer = styled.tr`
  &:nth-of-type(odd) {
    background-color: var(--label-color);
  }

  &:hover {
    background-color: grey;
  }
`

export default function LeaderBoard() {

  const [isLoading, setIsLoading] = useState(true)
  const [isStreamOnly, setIsStreamOnly] = useState(false)
  const [isStreamStatusLoading, setIsStreamStatusLoading] = useState(false)
  const [date, setDate] = useState("")
  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [twitchPlayer, setTwitchPlayer] = useState(null)

  const { isDesktopBrowsing } = useMedia()

  const handleBattletagClick = (e, streamUrl) => {
    const match = streamUrl.match(/www.twitch.tv\/(.*)/)
    if (match === null) return

    e.preventDefault()
    const channel = match[1]
    var options = {
      width: "100%",
      height: "400",
      channel: channel,
      muted: true,
    };
    if (twitchPlayer === null) {
      setTwitchPlayer(new Twitch.Player("twitch-embed", options))// eslint-disable-line
    }
    else {
      twitchPlayer.setChannel(channel)
    }
  }

  const columns = useColumns(handleBattletagClick)

  useEffect(() => {
    (async function () {
      const leaderboard = await leaderBoardService.getAll()
      if (leaderboard.players === undefined) return

      const streams = await leaderBoardService.getAllStreams()

      leaderboard.players.forEach(player => {
        player.hotsistPt = player.points * player.winCount / 100
        player.ratio = `${Math.floor((player.winCount / player.gameCount) * 100)}%`
        player.mainRolePickPercent = `${player.mainRolePick}%`
        const stream = streams.find(stream => stream.battletag === player.battletag || (Array.isArray(stream.battletag) && stream.battletag.includes(player.battletag)))
        if (stream) {
          player.streamUrl = stream.url
          player.isLive = stream.isLive
          player.streamLogoUrl = streamService.getStreamLogoUrl(stream.url)
        }
      })

      let globalRank = 1
      leaderboard.players = leaderboard.players.sort((a, b) => { return b.hotsistPt > a.hotsistPt ? 1 : -1 })
      leaderboard.players.forEach(player => {
        player.globalRank = globalRank++
      })

      setDate(leaderboard.date)
      setData(leaderboard.players)
      setFilteredData(leaderboard.players)
      setIsLoading(false)
    })()
  }, [])

  useEffect(() => {
    if (!isStreamOnly) {
      setFilteredData(data)
    }
    else {
      if (data.length > 0) {
        const streamOnlyData = data.filter(d => d.streamUrl)
        setFilteredData(streamOnlyData)
      }
    }
  }, [isStreamOnly, data])

  const setStreamLiveStatus = async () => {
    setIsStreamStatusLoading(true)
    const streams = await streamService.getAll()

    filteredData.forEach(player => {
      const stream = streams.find(stream => stream.battletag === player.battletag || (Array.isArray(stream.battletag) && stream.battletag.includes(player.battletag)))
      if (stream) {
        player.streamUrl = stream.url
        player.isLive = stream.isLive
      }
    })
    setFilteredData([...filteredData])
    setIsStreamStatusLoading(false)
  }

  return (
    <div>
      <div>
        <HeaderInfoContainer>Data from <Link href="https://heroesofthestorm.com/en-gb/leaderboards">HOTS Leaderboards page 🡕</Link></HeaderInfoContainer>
        {date && <DateContainer>{date}</DateContainer>}
      </div>
      <div>
        <input type="button" value="twitch live status" disabled={isStreamStatusLoading} onClick={setStreamLiveStatus} />
        {isStreamStatusLoading && <LoadingIcon />}
        <label>
          <Switch
            color="primary"
            checked={isStreamOnly}
            onChange={(e) => setIsStreamOnly(e.target.checked)}
          />
          Streamers only
        </label>
      </div>
      <div style={{ position: "sticky", height: "0", top: "100px", display: "flex" }}>
        <div style={{ position: "absolute", left: "50%", width: "50%" }} id="twitch-embed"></div>
        {twitchPlayer !== null &&
          <div
            style={{ cursor: "pointer", position: "relative", top: "-20px", left: "100%", width: "20px", textAlign: "center" }}
            onClick={() => {
              document.getElementById("twitch-embed").innerHTML = ""
              setTwitchPlayer(null)
            }}>X</div>
        }
      </div>
      <div>
        <SimpleTable columns={columns} rows={filteredData} isLoading={isLoading}
          options={
            {
              isRowNumberDisplayed: true,
              StyledTable: isDesktopBrowsing ? undefined : MobileTableContainer,
              StyledRow: TableRowContainer,
            }
          }
        />
      </div>
    </div>
  )
}
