import * as React from "react";

import TalentPick from "./TalentPick";

interface IProps {
  code: string;
  tierTalentCount: string;
  build: string;
  images: string[];
  detail?: string;
}

function HeroBuild(props: IProps) {
  const generateTalentPicks = () => {
    const picks = [];
    for (var i = 0; i < 7; i++) {
      picks.push(
        <TalentPick
          key={`${props.code}${i}`}
          tierTalentCount={parseInt(props.tierTalentCount[i])}
          pickIndex={parseInt(props.build[i]) - 1}
          imageUrl={`images/${props.code}/${props.images[i]}`}
        />
      );
    }
    return picks;
  };

  return (
    <div>
      <div style={{ display: "flex" }}>
        <div>
          <div style={{ width: "128px" }}>
            <img src={`images/${props.code}/portrait.png`} alt={props.code} />
          </div>
          <div>{props.build}</div>
        </div>
        {generateTalentPicks()}
      </div>
      {props.detail && <div style={{ textAlign: "center" }}>expand for more detail</div>}
    </div>
  );
}

export default HeroBuild;
