import * as React from 'react'

import RoleIcon, { IRoleIconProps } from './RoleIcon'

function SupportIcon(props: IRoleIconProps) {
    return (
        <RoleIcon title="Support" onClick={props.onClick} isActive={props.isActive}>
            <path d="M204.4,106.09,177.2,128A76.41,76.41,0,0,0,181,106.09c0-11.16-3.81-22.41-3.81-22.41Zm-52,0A44.38,44.38,0,1,1,108,61.73,44.37,44.37,0,0,1,152.38,106.09ZM125.66,170.5v6.66c0,7.63,6.2,9.06,6.2,9.06a81.83,81.83,0,0,1-10,9.54A118.65,118.65,0,0,1,108,204.83h0a118.65,118.65,0,0,1-13.84-9.07,81.83,81.83,0,0,1-10-9.54s6.2-1.43,6.2-9.06V170.5a66.79,66.79,0,0,1-.47-128.69V31.69s-.11-6.76-6.21-7.63l20.52-12.4v44a50.59,50.59,0,1,0,7.64,0v-44l20.52,12.4c-6.1.87-6.21,7.63-6.21,7.63V41.81a66.79,66.79,0,0,1-.47,128.69ZM11.6,106.09,38.8,83.68S35,94.93,35,106.09A76.41,76.41,0,0,0,38.8,128Z"></path>
        </RoleIcon>
    )
}

export default SupportIcon