import { useMemo, useState } from "react";
import { Route, Routes } from "react-router-dom";

import { ContentBlockElement, Layout, SideBarBlock } from "@gamate/components/layout";
import { Link } from "@gamate/components";

import { routes } from "./tools/routes";
import Build from "./pages/Build";
import Event from "./pages/Event";
import LeaderBoard from "./pages/LeaderBoard";
import Matchup from "./pages/Matchup";
import TierListPage from "./pages/TierList";
import { config } from "./tools/config";

import "./App.css";

export default function App() {
  const [pageTitle, setPageTitle] = useState("");

  const leftMenuDisplay = useMemo(() => {
    const menu = new Map([
      [routes.LeaderBoard, "Leaderboard"],
      [routes.TierList, "Tierlists"],
      [routes.Matchup, "Matchup"],
    ]);
    return [...menu.entries()].map((kvp) => (
      <Link key={kvp[0]} to={kvp[0]} className="link" onClick={() => setPageTitle(kvp[1])}>
        <ContentBlockElement>{kvp[1]}</ContentBlockElement>
      </Link>
    ));
  }, []);

  return (
    <>
      {/* <Header
        infoSiteLabel="Your game partner"
        menu={
          new Map([
            [routes.LeaderBoard, "leaderboard"],
            [routes.TierList, "tier lists"],
            [routes.Matchup, "matchup"],
          ])
        }
      /> */}
      <Layout
        config={config}
        pageTitle={pageTitle}
        leftSideContent={<SideBarBlock>{leftMenuDisplay}</SideBarBlock>}
      >
        <Routes>
          <Route path={routes.LeaderBoard} element={<LeaderBoard />} />
          <Route path={routes.TierList} element={<TierListPage />} />
          <Route path={`${routes.Matchup}`} element={<Matchup />}>
            <Route path={`:version/:code`} element={<Matchup />} />
          </Route>
          <Route path={routes.Event} element={<Event />} />
          <Route path={routes.Build} element={<Build />} />
        </Routes>
      </Layout>
    </>
  );
}
