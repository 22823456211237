export const buildService = {
  getAll: async () => {
    const ana = {
      code: "ana",
      tierTalentCount: "3332334",
      build: "2221331",
      images: [
        "sleep_dart.png",
        "sleep_dart.png",
        "sleep_dart.png",
        "nano_bost.png",
        "healing_dart_b.png",
        "healing_dart_b.png",
        "nano_bost.png",
      ],
    };
    const anduin = {
      code: "anduin",
      tierTalentCount: "3332344",
      build: "2132142",
      images: [
        "divine_star.png",
        "divine_star.png",
        "desperate_prayer.png",
        "light_bomb.png",
        "divine_star.png",
        "inner_focus.png",
        "light_bomb.png",
      ],
    };
    const malfurion = {
      code: "malfurion",
      tierTalentCount: "3332334",
      build: "3111121",
      images: [
        "entanglingroots_a.png",
        "regrowth.png",
        "wildgrowth.png",
        "tranquility.png",
        "regrowth.png",
        "wildgrowth.png",
        "tranquility.png",
      ],
    };

    const ragnaros = {
      code: "ragnaros",
      tierTalentCount: "3332334",
      build: "3232232",
      images: [
        "blastwave.png",
        "blastwave.png",
        "../autoattack_damage.png",
        "lavawave.png",
        "blastwave.png",
        "blastwave.png",
        "lavawave.png",
      ],
    };

    return Promise.resolve([ana, anduin, malfurion, ragnaros]);
  },
};